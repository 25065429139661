import * as Sentry from '@sentry/remix';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { StrictMode, startTransition, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

const integrations = [
  Sentry.browserTracingIntegration( {
    useEffect,
    useLocation,
    useMatches
  } ), 
]

if ( process.env.NODE_ENV === 'production' ) {
  integrations.push(
    Sentry.replayIntegration()
  )
}

Sentry.init( {
  dsn: 'https://20be5953338c4955e352bc071c59e7fb@o4505040793567232.ingest.us.sentry.io/4506920856715264',
  tracesSampleRate: 0.1,
  environment: window.env.NODE_ENV === 'production' ? 'production' : 'development',
  enabled: process.env.NODE_ENV === 'production',
  tracePropagationTargets: ['rewarble.com', 'vercel.app', 'rewarble-web.dev'],
  autoSessionTracking: true,
  attachStacktrace: true,
  integrations,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0,
} )

const hydrate = () => {
  startTransition( () => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>
    );
  } );
}

if ( typeof requestIdleCallback === 'function' ) {
  requestIdleCallback( hydrate );
} else {
  setTimeout( hydrate, 1 );
}